import React, { Component } from "react";
import Slide from "react-reveal";

class Services extends Component {
  getRandomColor() {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  detailList(detail) {
    const toArray = detail.split(";");
    toArray.forEach((element) => {
      return <p>{{ element }}</p>;
    });
  }

  render() {
    if (!this.props.data) return null;
    const serviceCont = this.props.data.serviceCont;
    const workCont = this.props.data.workCont;
    const areaOfSpecialization = this.props.data.areaOfSpecialization;
    const serviceList = this.props.data.serviceList.map(function (serviceList) {
      return (
        <div>
          <h3>{serviceList.name}</h3>
          <p className="info">{serviceList.description}</p>
          <p>{serviceList.detail}</p>
        </div>
      );
    });

    const work = this.props.data.work.map(function (work) {
      return (
        <div key={work.company}>
          <h4>
            {work.company} - {work.title}
          </h4>
          <h5 className="info">{work.years}</h5>
          <p>{work.description}</p>
        </div>
      );
    });

    const specializations = this.props.data.specializations.map(
      (specializations) => {
        const backgroundColor = this.getRandomColor();
        const className = "bar-expand " + specializations.name.toLowerCase();
        const width = specializations.level;

        return (
          <li key={specializations.name}>
            <span
              style={{ width, backgroundColor }}
              className={className}
            ></span>
            <em>{specializations.name}</em>
          </li>
        );
      }
    );

    return (
      <section id="services">
      
          <div className="row education">
            <div className="three columns header-col">
              <h1>
                <span>{serviceCont}</span>
              </h1>
            </div>

            <div className="nine columns main-col">
              <div className="row item">
                <div className="twelve columns pp">{serviceList}</div>
              </div>
            </div>
          </div>
       
          <div className="row work">
            <div className="three columns header-col">
              <h1>
                <span>{workCont}</span>
              </h1>
            </div>

            <div className="nine columns main-col pp">{work}</div>
          </div>

        <Slide left duration={1300}>
          <div className="row skill">
            <div className="three columns header-col">
              <h1>
                <span>{areaOfSpecialization}</span>
              </h1>
            </div>
            <div className="nine columns main-col">
              <div className="bars">
                <ul className="skills">{specializations}</ul>
              </div>
            </div>
          </div>
        </Slide>
      </section>
    );
  }
}

export default Services;
