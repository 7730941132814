import React, { Component } from "react";
import Fade from "react-reveal";

class About extends Component {
  render() {
    if (!this.props.data) return null;

    // const name = this.props.data.name;
    // const profilepic = "images/" + this.props.data.image;
    const bio = this.props.data.bio;
    const vision = this.props.data.vision;
    const mission = this.props.data.mission;
    const philosophy = this.props.data.philosophy;

    return (
      <section id="about">
        <Fade duration={1000}>
          <div className="row">
            {/* <div className="three columns">
              <img
                className="profile-pic"
                src={profilepic}
                alt="SAN Profile Pic"
              />
            </div> */}
            <div className="twelve columns main-col">
              <h2>Who We Are</h2>
              <p>{bio}</p>

              <h2>Vision</h2>
              <p>{vision}</p>

              <h2>Mission</h2>
              <p>{mission}</p>

              <h2>Philosophy</h2>
              <p>{philosophy}</p>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default About;
